<!--
 * @Author: Link
 * @Date: 2020-12-15 09:15:19
 * @LastEditTime: 2021-11-26 19:16:00
-->
<template>
  <div class="invite">
    <div
      class="choose-btn"
      v-if="!choose.show && choose.templateList.length"
      @click="choose.show = true"
    >
      切换海报
    </div>
    <van-swipe
      class="swiper"
      :loop="false"
      :show-indicators="choose.show"
      :touchable="choose.show"
      :initial-swipe="choose.selected"
      @change="onSwiperChange"
    >
      <van-swipe-item
        class="swiper-item"
        v-for="(item, index) in choose.templateList"
        @click="handleChoose(index)"
        :key="item._id"
      >
        <!-- <img v-if="!choose.show" :src="choose.list[item._id]" class="img" /> -->
        <img :src="choose.list[item._id] || item.previewUrl" class="img" />
      </van-swipe-item>
    </van-swipe>
    <div class="empty" v-if="!choose.templateList.length && !loading">请联系管理员添加模板</div>
    <!-- <div class="container" ref="poster" :style="`height:${height}px`">
      <template>
        <img :src="base64Img" class="bg" @load="imgLoad" />
        <div
          class="box"
          ref="box"
          :style="`width:${qrcodeWidth}px;height:${qrcodeWidth}px;top:${qrcodeTop}px`"
        >
          <div class="canvas" id="qrCode" ref="qrCodeDiv"></div>
        </div>
      </template>
      <img class="poster-img" v-if="Boolean(posterUrl)" :src="posterUrl" />
    </div> -->
    <div class="mask" v-if="loading">
      <van-loading />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
import { Swipe, SwipeItem } from 'vant'
const baseURL = process.env.VUE_APP_baseUrl
import { miniAppUrlMap } from '@/util/codeUrl'
export default {
  name: 'share',
  components: { Swipe, SwipeItem },
  data() {
    return {
      // base64Img: '',
      // posterUrl: '',
      // qrcodeImg: '',
      // width: 0,
      // height: 0,
      // qrcodeWidth: 100,
      // qrcodeTop: 0,
      loading: true,
      choose: {
        show: true,
        templateList: [],
        list: {},
        selected: 0
      }
    }
  },
  created() {
    this.getTemplateList()

    // this.$http({
    //   method: 'get',
    //   url: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/invite_new.png',
    //   responseType: 'blob'
    // }).then(res => {
    //   this.blobToBase64(res).then(base => {
    //     this.base64Img = base
    //   })
    // })
  },
  mounted() {
    // let width = this.$refs.poster.clientWidth
    // this.width = width
    // this.height = width / (750 / 1334)
    // this.qrcodeWidth = width * 0.27
    // this.qrcodeTop = this.height * 0.74
    // this.$nextTick(() => {
    //   if (this.userInfo.id) {
    //     // this.createQRcode(document.location.origin + '?id=' + this.userInfo.id)
    //     this.createQRcode(
    //       `http://${
    //         process.env.VUE_APP_ENV === 'prod' ? 'miniapp.boomsj.com' : 'miniapp.tanchi0757.shop'
    //       }/distribution?inviteId=${this.userInfo.id}`
    //     )
    //   } else {
    //     Dialog.alert({
    //       message: '用户信息丢失，请重新登录'
    //     })
    //   }
    // })
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      isBmMiniApp: 'sys/isBmMiniApp'
    })
  },
  methods: {
    // imgLoad() {
    //   // this.base64Img = this.img2Base64(this.$refs.bg)
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       this.htmlTocanvas()
    //     }, 1000)
    //   })
    // },
    // img2Base64(img) {
    //   var canvas = document.createElement('canvas')
    //   canvas.width = img.width
    //   canvas.height = img.height
    //   var ctx = canvas.getContext('2d')
    //   ctx.drawImage(img, 0, 0, img.width, img.height)
    //   var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
    //   var dataURL = canvas.toDataURL('image/' + ext)
    //   return dataURL
    // },
    // blobToBase64(blob) {
    //   return new Promise((resolve, reject) => {
    //     const fileReader = new FileReader()
    //     fileReader.onload = e => {
    //       resolve(e.target.result)
    //     }
    //     // readAsDataURL
    //     fileReader.readAsDataURL(blob)
    //     fileReader.onerror = () => {
    //       reject(new Error('blobToBase64 error'))
    //     }
    //   })
    // },
    // createQRcode(url) {
    //   let width = this.$refs.box.clientWidth
    //   width = parseInt(width) - 20
    //   new QRCode(this.$refs.qrCodeDiv, {
    //     text: url,
    //     width,
    //     height: width,
    //     colorDark: '#333333', //二维码颜色
    //     colorLight: '#ffffff', //二维码背景色
    //     correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
    //   })
    // },
    // htmlTocanvas() {
    //   this.loading = false
    //   html2canvas(this.$refs.poster, {
    //     tainttest: true,
    //     allowTaint: true,
    //     useCORS: true,
    //     height: parseInt(this.height),
    //     width: parseInt(this.width)
    //   }).then(canvas => {
    //     let imageUrl = canvas.toDataURL('image/png') // 将canvas转成base64图片格式
    //     this.posterUrl = imageUrl
    //     this.loading = false
    //   })
    // },
    // save() {
    //   var alink = document.createElement('a')
    //   alink.href = this.posterUrl
    //   alink.download = 'share' //图片名
    //   alink.click()
    // },

    getTemplateList() {
      this.loading = true
      this.$http.get('boom-px-api-service/template?size=-1&category=distrubution').then(res => {
        this.choose.templateList = res.list
        if (localStorage['choosedPosterId'] != undefined) {
          let id = localStorage['choosedPosterId']
          let index = res.list.findIndex(item => item._id == id)
          this.choose.selected = index
        }
        res.list.map(item => {
          this.getPoster(item._id)
        })
      })
    },
    getPoster(id) {
      this.loading = true
      let url = `${miniAppUrlMap[localStorage['tenantId']]}/distribution?inviteId=${
        this.userInfo.id
      }`
      this.$http
        .post('boom-mix-biz-service/app/poster/distribution/generate', {
          ordinaryQrCodeUrl: url,
          posterTempId: id
        })
        .then(res => {
          this.$set(this.choose.list, id, res)
        })
        .finally(() => (this.loading = false))
    },
    handleChoose(index) {
      return
      this.choose.show = false
      if (!this.choose.list[this.choose.templateList[index]._id]) {
        setTimeout(() => {
          this.getPoster(this.choose.templateList[index]._id)
        }, 500)
      }
      localStorage['choosedPosterId'] = this.choose.templateList[index]._id
    },
    onSwiperChange(index) {
      localStorage['choosedPosterId'] = this.choose.templateList[index]._id
    }
  }
}
</script>

<style lang="less" scope>
.van-swipe__indicator {
  background: rgba(0, 0, 0, 0.3);
}
.van-swipe__indicator--active {
  background: red;
}
.invite {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background: #d63336;
  .choose-btn {
    position: absolute;
    z-index: 9;
    top: 15px;
    right: 15px;
    background: #fff;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 10px;
    // background: #e0e0e0;
    background: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      filter: blur(10px);
    }
  }
  .swiper {
    width: 100%;
    height: 100vh;
    background: #fff;
    .swiper-item {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .img {
        border-radius: 10px;
        transition: all 0.3s;
        box-shadow: 32px 32px 64px #d0d0d0, -32px -32px 64px #f0f0f0;
        width: 100%;
        height: auto;
        position: relative;
        transform: scale(0.85);
      }
    }
  }
  .empty {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #555555;
  }
  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    .poster-img {
      position: absolute;
      z-index: 10;
      top: 0;
      left: -10px;
      width: calc(100% + 10px);
      height: 100%;
    }
    .bg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
    .box {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .panel {
    background: #fff;
    width: calc(100% - 80px);
    height: auto;
    margin: 40px;
    border-radius: 5px;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.07);
    .code {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      border-radius: 5px;
      text-align: center;
      background-color: #e7141a;
      position: relative;
      .code-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10rem;
        height: 10rem;
        padding-bottom: 50%;
        border-radius: 8px;
        overflow: hidden;
        padding: 10px;
        .box {
          width: 100%;
          height: 100%;
          background: #fff;
          position: relative;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;
        }
        .bg {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
        }
        .loading {
          position: absolute;
          z-index: -1;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .img {
          margin: 10px;
          width: calc(100% - 25px);
          height: auto;
          border-radius: 5px;
          object-fit: contain;
        }
      }
    }
    .user {
      text-align: center;
      position: relative;
      padding-bottom: 30px;
      z-index: 2;
      .avatar {
        position: relative;
        z-index: 1;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-top: -30px;
      }
      .name {
        font-size: 15px;
        font-weight: bold;
        color: #555555;
        margin: 10px 0;
      }
      .tip {
        font-size: 10px;
        color: #999999;
      }
    }
  }
  .share {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    .item {
      font-size: 10px;
      color: #999999;
      text-align: center;
      .icon {
        width: 60px;
        height: 60px;
        &:active {
          filter: brightness(95%);
        }
      }
    }
  }
  .canvas {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // position: fixed;
    // left: -500%;
    // z-index: -1;
  }
}
</style>
